<template>
    <div class="view pa24">
        <div class="selectCompany flex-a-c hover_pointer" @click="showCompanyList"><i
                class="el-icon-office-building fs12 mr5"></i> <span class="fs13">{{ checkedCompany.companyName }}</span>
            <i class="el-icon-caret-bottom  fs12 ml5"></i>
        </div>
        <div class="mb20">
            <el-tabs v-model="year" @tab-click="getData()">
                <el-tab-pane :label="String(y) + '年'" :name="String(y)" v-for="(y, index) in yearData"
                    :key="index"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="mb10 mt10 flex-a-c">
            <el-input class="mr10" style placeholder="请输入人员岗位查找,可以用（，）分隔" v-model="work" @change="getData()" />
            <el-input class="mr10" style placeholder="请输入人员姓名查找,可以用（，、）分隔" v-model="userName" @change="searchUser" />
            <div class="mr20 mw250">
                <el-button type="primary" :disabled="!checkedCompany.pcId" @click="confirmData()"
                    v-loading="btnLoading">确认修改分配项目</el-button>
                <el-button type="info" @click="getData()">重 置</el-button>
            </div>
            <div class="mw400">
                <div class="flex-a-c mb10">
                    <div>数据状态：</div>
                    <div class="flex-a-c">
                        <div class="noFind w20 h20"></div>
                        <span class="ml10">错误分配数据</span>
                    </div>
                    <div class="flex-a-c ml20">
                        <div class="baffefd3 w20 h20"></div>
                        <span class="ml10">查找数据</span>
                    </div>
                </div>
                <div class="flex-a-c">
                    <div>选项状态：</div>
                    <div class="flex-a-c">
                        <el-checkbox class="canSelect" disabled v-model="isSelect"></el-checkbox>
                        <span class="ml10">不在项目日期内又选中</span>
                    </div>
                    <div class="flex-a-c ml20">
                        <el-checkbox disabled v-model="isSelect2"></el-checkbox>
                        <span class="ml10">不在项目日期内</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="oyauto">
            <table border="1" cellspacing="0" class="assistrecordTable" :style="tableStyle" v-loading="loading">
                <thead>
                    <tr class="h30 baf7fafa">
                        <td class="textc itemTitle" :colspan="6 + (projectDataList.length)">项目人员分配地图</td>
                    </tr>
                    <tr class="h30 baf7fafa">
                        <td class="textc itemTitle w90 mw100">{{ year }}</td>
                        <td class="textc itemTitle w100 mw100" rowspan="2">名称</td>
                        <td class="textc itemTitle w120 mw120" rowspan="2">岗位</td>
                        <td class="textc itemTitle w120 mw120" rowspan="2">入职日期</td>
                        <td class="textc itemTitle w120 mw120" rowspan="2">离职日期</td>
                        <td class="textc itemTitle mw120" v-for="(row, index) in projectDataList" :key="index">
                            {{ row.projectNo }}</td>
                        <td class="textc itemTitle w120 mw120">参与项目数</td>
                    </tr>
                    <tr class="h30 baf7fafa">
                        <td class="textc itemTitle w90 mw100">序号</td>
                        <td class="textc itemTitle" v-for="(row, index) in projectDataList" :key="index">
                            <div>{{ row.startDay }}</div>
                            <div>{{ row.endDay }}</div>
                        </td>
                        <td class="textc itemTitle"></td>
                    </tr>
                </thead>
                <tbody v-if="projectUserList.length > 0" :style="{ 'max-height': tableHeight + 'px' }"
                    class="overflowHide">
                    <tr class="h30" v-for="(row, index) in projectUserList" :key="index"
                        :class="{ 'noFind': row.noFind, 'baffefd3': searchPuId.indexOf(row.puId) != -1 }">
                        <td class="textc itemContent">
                            <el-tooltip class="item" effect="dark" v-if="row.noFind"
                                :content="'未分配月份' + row.noFindMonth + '月'" placement="top">
                                <div>{{ index + 1 }}</div>
                            </el-tooltip>
                            <div v-else>{{ index + 1 }}</div>
                        </td>
                        <td class="textc itemContent">
                            <el-tooltip class="item" effect="dark" v-if="row.noFind"
                                :content="'未分配月份' + row.noFindMonth + '月'" placement="top">
                                <div>{{ row.userName }}</div>
                            </el-tooltip>
                            <div v-else>{{ row.userName }}</div>
                        </td>
                        <td class="textc itemContent">
                            <el-tooltip class="item" effect="dark" v-if="row.noFind"
                                :content="'未分配月份' + row.noFindMonth + '月'" placement="top">
                                <div>{{ row.work }}</div>
                            </el-tooltip>
                            <div v-else>{{ row.work }}</div>
                        </td>
                        <td class="textc itemContent">
                            <el-tooltip class="item" effect="dark" v-if="row.noFind"
                                :content="'未分配月份' + row.noFindMonth + '月'" placement="top">
                                <div>{{ row.inTime }}</div>
                            </el-tooltip>
                            <div v-else>{{ row.inTime }}</div>
                        </td>
                        <td class="textc itemContent">
                            <el-tooltip class="item" effect="dark" v-if="row.noFind"
                                :content="'未分配月份' + row.noFindMonth + '月'" placement="top">
                                <div>{{ row.outTime }}</div>
                            </el-tooltip>
                            <div v-else>{{ row.outTime }}</div>
                        </td>
                        <td class="textc itemContent" v-for="(child, key) in row.inProject" :key="key">
                            <el-tooltip class="item" effect="dark" v-if="row.noFind"
                                :content="'未分配月份' + row.noFindMonth + '月'" placement="top">
                                <div>
                                    <!-- <div v-if="!child.canSelect && !child.isSelect" class="el-icon-error e"></div> -->
                                    <el-checkbox :class="{ 'canSelect': !child.canSelect }"
                                        :disabled="!child.canSelect && !child.isSelect" v-model="child.isSelect"
                                        @change="processingData"></el-checkbox>
                                </div>
                            </el-tooltip>
                            <div v-else>
                                <!-- <div v-if="!child.canSelect && !child.isSelect" class="el-icon-error e"></div> -->
                                <el-checkbox :class="{ 'canSelect': !child.canSelect }"
                                    :disabled="!child.canSelect && !child.isSelect" v-model="child.isSelect"
                                    @change="processingData"></el-checkbox>

                            </div>
                        </td>
                        <td class="textc itemContent">{{ row.participateNumber }}</td>
                    </tr>
                </tbody>
                <tbody v-else :style="{ 'max-height': tableHeight + 'px' }" class="overflowHide">
                    <tr class="h100">
                        <td class="textc itemContent">
                            <div>暂无分配人员</div>
                        </td>
                    </tr>
                </tbody>
                <tfoot v-if="projectUserList.length > 0">
                    <tr class="h30 baf7fafa">
                        <td class="textc itemTitle noFind">未分配人数</td>
                        <td class="textc itemTitle noFind">{{ noFindCount }}</td>
                        <td class="textc itemTitle">合计人数</td>
                        <td class="textc itemTitle">{{ projectUserList.length }}</td>
                        <td class="textc itemTitle">-</td>
                        <td class="textc itemTitle" v-for="(child, key) in totalData" :key="key">{{ child }}</td>
                        <td class="textc itemTitle">-</td>
                    </tr>
                </tfoot>
            </table>
        </div>
        <select-company-list ref="selectCompanyList" @confirmFun="selectCompany"></select-company-list>
    </div>
</template>

<script>
import { getProjectDataAndUserDataList, setProjectDataAndUserData } from "@/api/projectAdmin";
import selectCompanyList from "@/pages/projectAdmin/components/selectCompanyList";
export default {
    components: {
        selectCompanyList,
    },
    data() {
        return {
            isSelect: true,
            isSelect2: false,
            //列表配置
            projectUserList: [],
            projectDataList: [],
            totalData: [],
            checkedCompany: {},
            yearData: [],
            year: "",
            loading: false,
            btnLoading: false,
            tableHeight: 400,
            userName: "",
            searchPuId: [],
            tableStyle: {},
            work: "",
        };
    },
    computed: {
        noFindCount() {
            let count = 0;
            for (let row of this.projectUserList) {
                if (row.noFind) {
                    count++;
                }
            }
            return count;
        }
    },
    filters: {
        setMoney(money) {
            return (money ? (money / 10000) : 0).toFixed(2)
        }
    },
    mounted() {
        this.tableHeight = $(".content").height() - 75 - (40 + 56 + 74) - (120);
        let checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
        if (!checkedCompany) {
            this.showCompanyList();
        }
        this.checkedCompany = checkedCompany ? checkedCompany : { companyName: "请选择研发企业" };
        this.getYearData();
        this.getData();
    },
    methods: {
        searchUser() {
            this.searchPuId = [];
            let userName = this.userName.replace(/\s+/ig, "、").replace(/\,|，/ig, "、");
            let userArr = userName.split("、");
            for (let i in this.projectUserList) {
                if (userArr.indexOf(this.projectUserList[i].userName) != -1) {
                    this.searchPuId.push(this.projectUserList[i].puId);
                }
            }
        },
        /**@method 获取数据 */
        confirmData() {
            this.$confirm('此操作将影响到辅助账分配金额, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
               //-console.log("此操作将影响到辅助账分配金额");
                let requestObj = {};
                let requestList = [];
                for (let row of this.projectUserList) {
                    for (let i in row.inProject) {
                        if (row.inProject[i].isSelect) {
                            if (requestObj[this.projectDataList[i].pdId]) {
                                requestObj[this.projectDataList[i].pdId].push(row.puId);
                            } else {
                                requestObj[this.projectDataList[i].pdId] = [row.puId];
                            }
                        }
                    }
                }
                for (let pdId in requestObj) {
                    requestList.push({
                        pdId: pdId,
                        members: requestObj[pdId].join(",")
                    });
                }
                let params = {
                    requestList: requestList
                };
                setProjectDataAndUserData(params).then(res => {
                    this.btnLoading = false;
                    if (res.code == 200) {
                        this.$message.success("已分配完成");
                        this.getData();
                    } else {
                        this.$message.error(res.meesage);
                    }
                }).catch((err) => {
                    if (err.code != 201) {
                        this.$message.error(err.meesage);
                    }
                    this.btnLoading = false;
                });
            }).catch((err) => {
               //-console.log(err);
            });
        },
        /**@method 显示研发企业列表 */
        showCompanyList() {
            this.$refs.selectCompanyList.showModelFun();
        },
        /**@method 选择研发企业 */
        selectCompany(row) {
            this.checkedCompany = row;
            sessionStorage.setItem("checkedProjectCompany", JSON.stringify(row));
            this.getData();
        },
        /**@method 获取最近五年 */
        getYearData() {
            let yearData = [];
            let F = new Date().getFullYear();
            for (let i = 0; i <= 4; i++) {
                yearData.push(F - i);
            }
            this.yearData = yearData;
            this.year = String(this.yearData[0]);
        },
        /**@method 数据处理 */
        processingData() {
            let projectUserList = [];
            let totalData = [];
            for (let row of this.projectUserList) {
                row.inTime = row.inTime ? row.inTime.slice(0, 10) : "-";
                row.outTime = row.outTime ? row.outTime.slice(0, 10) : "-";
                row.participateNumber = 0;
                for (let key in row.inProject) {
                    if (!totalData[key]) {
                        totalData[key] = 0;
                    }
                    if (row.inProject[key].isSelect) {
                        row.participateNumber++;
                        totalData[key]++;
                    }
                }
                projectUserList.push(row);
            }
            this.totalData = totalData;
            this.projectUserList = projectUserList;
        },
        /***@method 获取数据 */
        getData() {
            let params = {
                selectYear: this.year
            };
            if (this.work) {
                params.work = this.work;
            }
            this.projectUserList = [];
            this.projectDataList = [];
            if (!this.checkedCompany.pcId) {
                return;
            } else {
                params.pcId = this.checkedCompany.pcId;
            }
            this.loading = true;
            getProjectDataAndUserDataList(params).then(res => {
                this.loading = false;
                if (res.data) {
                    this.projectUserList = res.data.projectUserList;
                    this.projectDataList = res.data.projectDataList;
                    if (this.projectDataList) {
                        let tableW = 700 + (this.projectDataList.length * 120);
                        if (tableW > $(".view").width()) {
                            this.tableStyle = {
                                width: tableW + 'px'
                            }
                        } else {
                            this.tableStyle = {
                                width: 'auto'
                            };
                        }

                    } else {
                        this.tableStyle = {
                            width: 'auto'
                        };
                    }
                    this.processingData();
                }
            }).catch(err => {
                this.loading = false;
            })
        }
    },
};
</script>

<style lang="scss" scoped>
.assistrecordTable {
    /deep/.el-checkbox__inner {
        border: 1px solid #51CDCB;
    }
}

.noFind,
.noFind:hover {
    background-color: #ffb4aa !important;
    color: #fff;
}

.canSelect {
    /deep/.el-checkbox__inner {
        border: 1px solid #DCDFE6;
    }

    /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #FD634E;
        border-color: #FD634E;
    }
}

.selectCompany {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 700;
    color: #51CBCD;
    cursor: pointer;
}

.assistrecordTable {

    thead,
    tbody tr,
    tfoot tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    tbody {
        display: block;
    }

    .itemTitle {
        font-size: 16px;
        padding: 5px 10px;

        >div {
            font-size: 16px;
        }
    }

    .itemContent {
        min-height: 30px;
        font-size: 16px;
        padding: 5px 10px;
    }

    .specialTable {
        position: relative;
        overflow: hidden;

        .titleLeft {
            position: absolute;
            left: 5px;
            bottom: 0px;
            font-size: 16px;
        }

        .titleRight {
            position: absolute;
            right: 5px;
            top: 0px;
            font-size: 16px;
        }

        .titleCenter {
            position: absolute;
            right: 5px;
            bottom: 0px;
            font-size: 16px;
        }

        .prohibit {
            transform-origin: left top;
            height: 0px;
            border-top: 1px solid #999;
            transform: rotate(13deg);
            -o-transform: rotate(13deg);
            -moz-transform: rotate(13deg);
            -webkit-transform: rotate(13deg);
            border-radius: 4px;
            position: absolute;
            top: 0;
            left: 0px;
        }

        .prohibit2 {
            position: absolute;
            top: 0;
            left: 0;
            height: 0px;
            border-top: 1px solid #999;
            transform: rotate(35deg);
            -o-transform: rotate(35deg);
            -moz-transform: rotate(35deg);
            -webkit-transform: rotate(35deg);
            border-radius: 4px;
            transform-origin: left top;
        }
    }
}
</style>